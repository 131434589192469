import type { HebeModel, Vertical, SlotsConfigFromHebe } from '@sbt-web/adv';

type ErrorType = 'notfound' | 'gone';

const createErrorConfig =
  (errorType: ErrorType, errorCode: string) =>
  (vertical: Vertical): HebeModel => {
    const name = `error${errorType}`;

    const createSlot = (position: 'left' | 'right'): SlotsConfigFromHebe => ({
      container_id: `${name}-skyscraper${position}`,
      name: `skyscraper ${position}`,
      configurations: {
        gam: [
          {
            sequence: 0,
            unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-${errorCode}-skyscraper${position}`,
            sizes: ['300x600', '300x250', '160x600'] as `${number}x${number}`[],
            bidding_systems: [
              {
                name: 'criteo',
                params: {
                  networkId: 280,
                },
              },
              {
                name: 'rubicon',
                params: {
                  accountId: 23660,
                  siteId: 381482,
                  zoneId: 2114030,
                },
              },
              {
                name: 'ix',
                params: {
                  siteId: 1131817,
                },
              },
              {
                name: 'pubmatic',
                params: {
                  publisherId: '81997',
                  adSlot: 'Subito_300x600-hb@300x600',
                },
              },
              {
                name: 'pubmatic',
                params: {
                  publisherId: '81997',
                  adSlot: 'Subito_300x250-hb@300x250',
                },
              },
              {
                name: 'openx',
                params: {
                  delDomain: 'adevintaitaly-d.openx.net',
                  unit: 541156882,
                },
              },
              {
                name: 'openx',
                params: {
                  delDomain: 'adevintaitaly-d.openx.net',
                  unit: 541156881,
                },
              },
              {
                name: 'appnexus',
                params: {
                  placementId: 19698611,
                },
              },
              {
                name: 'appnexus',
                params: {
                  placementId: 19698617,
                },
              },
              {
                name: 'teads',
                params: {
                  pageId: 149522,
                  placementId: 163688,
                },
              },
              {
                name: 'pubmatic',
                params: {
                  publisherId: '81997',
                  adSlot: 'Subito_160x600-hb@160x600',
                },
              },
            ],
          },
        ],
      },
    });

    return {
      name,
      page_level_settings: {},
      slots: [createSlot('left'), createSlot('right')],
    };
  };

export const errorNotFoundConfig = createErrorConfig('notfound', '404');
export const errorGoneConfig = createErrorConfig('gone', '410');
