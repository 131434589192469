import { updateFiltersLabelsBulk, type SearchState } from '@reducers/search';
import {
  AnubisClient,
  CategoryId,
  HTTPStatusCode,
  Orders,
  TuttaItalia,
  type AdItem,
  type SearchParams,
  type SearchResponse,
} from '@sbt-web/networking';
import { isEmpty } from '@sbt-web/utils';
import { captureException } from '@sentry/nextjs';
import { ADS_PER_PAGE, WEB_API_CHANNEL } from '@shared/constants';
import { DecoratedItem, GalleryItem, GeoboostItem } from '@shared/models';
import { getRankedItems, RankedItem } from '@shared/helpers/Rank/store';
import {
  afterPlugins,
  beforePlugins,
  ListingPlugin,
  matchCategories,
  shouldAddToListing,
} from '@shared/models/ListingPlugins';
import { all } from '@shared/models/ListingPlugins/matchers';
import { galleryPosition } from '@shared/models/ListingPlugins/position';
import {
  registerError,
  type ErrorSeverity,
  getErrorMessage,
} from '@tools/errorHelpers';
import { providePlugins } from '@tools/listingPlugins';
import { getFiltersConfigStore } from '@tools/search';
import type { Response } from 'express';
import { ActionCreator, Reducer } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import type { RootState } from '../';
import type { SubitoAction } from '../common';
import { isSearchEligibleForGeoboost } from '@client/components/Adv/Geoboost/shared';

enum Types {
  UPDATE_ITEMS_REQUEST = 'update items request',
  UPDATE_ITEMS_SUCCESS = 'update items success',
  UPDATE_ITEMS_FAILURE = 'update items failure',
}

type ExternalActions = ReturnType<typeof updateFiltersLabelsBulk>;

type UpdateItemsActions =
  | ReturnType<typeof updateItemsFailure>
  | ReturnType<typeof updateItemsRequest>
  | ReturnType<typeof updateItemsSuccess>;

type ThunkActions = UpdateItemsActions | ExternalActions;

type ItemsActions = UpdateItemsActions;

const client = new AnubisClient(
  // Call Anubi directly on the server, and through the Hades subdomain on the client
  process.env.ANUBI_BASE_URL || process.env.NEXT_PUBLIC_HADES_BASE_URL,
  WEB_API_CHANNEL,
  Number.parseInt(process.env.NEXT_PUBLIC_ANUBI_TIMEOUT_MS, 10)
);

const defaultState: ItemsState = {
  list: [],
  rankedList: [],
  total: 0,
  totalPages: 0,
  hasError: false,
  loading: false,
};

export const items: Reducer<ItemsState, ItemsActions> = (
  state = defaultState,
  action
): ItemsState => {
  switch (action.type) {
    case Types.UPDATE_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Types.UPDATE_ITEMS_SUCCESS:
      return {
        list: action.payload.list,
        rankedList: action.payload.rankedList,
        total: action.payload.total,
        totalPages: Math.ceil(action.payload.total / ADS_PER_PAGE),
        hasError: false,
        loading: false,
      };
    case Types.UPDATE_ITEMS_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    default:
      return state;
  }
};

export function mapSearchStateToSearchParams(
  searchState: SearchState
): SearchParams {
  let params: SearchParams = {
    q: searchState.query,
    c:
      searchState.category.id !== CategoryId.Tutte
        ? searchState.category.id
        : undefined,
    r:
      searchState.geo.region.friendlyName !== TuttaItalia.friendlyName
        ? searchState.searchNearRegions
          ? searchState.geo.region.neighbors
          : searchState.geo.region.id
        : undefined,
    ci: searchState.geo?.city?.id,
    to: searchState.geo?.town?.id,
    z: searchState.geo?.zone?.id,
    t: searchState.adType,
    qso: searchState.qso,
    shp: searchState.includeShippableOnly,
    urg: searchState.includeUrgent,
    sort: searchState.category.orders[searchState.orderIndex],
    lim: ADS_PER_PAGE,
    start: searchState.page > 0 ? (searchState.page - 1) * ADS_PER_PAGE : 0,
  };

  if (searchState.radiusSearch != undefined) {
    const obj = {
      lat: searchState.radiusSearch.center.lat.toString(),
      lon: searchState.radiusSearch.center.lng.toString(),
      rad: searchState.radiusSearch.radiusMeters.toString(),
    };
    params = { ...params, ...obj };
  }
  const currentConfig = getFiltersConfigStore(
    searchState.category.id,
    searchState.adType
  );

  if (!isEmpty(searchState.filters) && currentConfig) {
    currentConfig.sparseFilters.forEach((filter) => {
      if (filter && searchState.filters[filter.queryString]) {
        params[filter.queryString] = searchState.filters[filter.queryString];
      }
    });
  }

  // We want to remove items with no price from the search results if it is sorted by price in ascending order
  if (params.sort === Orders.PriceAsc) {
    params.ps = searchState?.filters?.ps ?? '1';
  }

  return params;
}

export const updateItems: ActionCreator<
  ThunkAction<Promise<ThunkActions>, RootState, void, ThunkActions>
> = (
  res?: Response
): ((
  d: ThunkDispatch<RootState, void, ThunkActions>,
  g: () => RootState
) => Promise<ThunkActions>) => {
  return async (dispatch, getState): Promise<ThunkActions> => {
    dispatch(updateItemsRequest());
    try {
      const state = getState();
      const params = mapSearchStateToSearchParams(state.search);
      const environmentId = state.env.id || undefined;

      const itemsPromise = client.search(params, undefined, environmentId);
      const galleryPromise = client.search(
        { ...params },
        { gallerized: true },
        environmentId
      );

      const geoboostPromise: Promise<SearchResponse> | undefined =
        isSearchEligibleForGeoboost(state.search)
          ? client.search(
              { ...params },
              { geoboosted: true, limit: 5 },
              environmentId
            )
          : undefined;

      const items = await itemsPromise;

      if (items.status !== HTTPStatusCode.OK || !items.search) {
        if (res) {
          handleInvalidResponse(res, 'FATAL', items);
        }
        return dispatch(updateItemsFailure());
      }

      if (__SERVER__ && items.search.filterLabels) {
        dispatch(updateFiltersLabelsBulk(items.search.filterLabels));
      }

      const { search: gallerySearch } = await galleryPromise;

      let galleryList: AdItem[] = [];
      if (gallerySearch) {
        galleryList = gallerySearch.ads;
      }

      const { ads: itemList, total } = items.search;
      const pageGalleryCount = galleryList.length;
      const pageAdsCount = itemList.length;

      const galleryPlugins = galleryList.map(
        (item): ListingPlugin => ({
          model: new GalleryItem(item),
          position: galleryPosition(),
          categories: all(),
        })
      );

      const boostedItems: Array<AdItem> =
        (await geoboostPromise)?.search?.ads ?? [];

      const validPlugins = flatMap(providePlugins(), (p: ListingPlugin) => {
        if (p.model.kind === 'GalleryPlaceholder') {
          return galleryPlugins;
        } else if (p.model.kind === 'GeoboostItem') {
          (p.model as GeoboostItem).boostedItems = boostedItems;
          return [p];
        } else {
          return [p];
        }
      })
        .filter(shouldAddToListing(pageAdsCount, pageGalleryCount, total))
        .filter(matchCategories(state.search.category.id));

      const rankedList: Array<RankedItem> = [];
      const decoratedItems = itemList.map((item, itemIndex) => {
        const before = validPlugins.filter(
          beforePlugins(pageAdsCount, itemIndex)
        );
        const after = validPlugins.filter(
          afterPlugins(pageAdsCount, itemIndex)
        );

        rankedList.push(...getRankedItems([...before, item, ...after]));
        return new DecoratedItem(before, item, after);
      });

      return dispatch(
        updateItemsSuccess({
          list: decoratedItems,
          rankedList,
          total,
        })
      );
    } catch (error) {
      captureException(`Failed to call Anubi: ${getErrorMessage(error)}`, {
        level: 'fatal',
      });

      return dispatch(updateItemsFailure());
    }
  };
};

function handleInvalidResponse(
  res: Response,
  severity: ErrorSeverity,
  items: SearchResponse
): void {
  const { status, ...itemsRest } = items;

  let calculatedStatus = status as HTTPStatusCode;

  // Based on Axios' code: https://github.com/axios/axios/blob/v1.6.2/lib/adapters/xhr.js#L170
  if (
    typeof itemsRest.payload === 'string' &&
    itemsRest.payload.includes('timeout') &&
    itemsRest.payload.includes('exceeded')
  ) {
    calculatedStatus = HTTPStatusCode.GatewayTimeout;
  }

  registerError(res, calculatedStatus, severity, {
    ...itemsRest,
    service: 'ANUBI',
    message: new Error('Request failed to Anubi.'),
  });
}

interface SuccessPayload {
  list: DecoratedItem[];
  rankedList: RankedItem[];
  total: number;
}

export function updateItemsSuccess(
  payload: SuccessPayload
): SubitoAction<Types.UPDATE_ITEMS_SUCCESS, SuccessPayload> {
  return { type: Types.UPDATE_ITEMS_SUCCESS, payload };
}

export function updateItemsRequest(): SubitoAction<Types.UPDATE_ITEMS_REQUEST> {
  return { type: Types.UPDATE_ITEMS_REQUEST, payload: undefined };
}

export function updateItemsFailure(): SubitoAction<Types.UPDATE_ITEMS_FAILURE> {
  return { type: Types.UPDATE_ITEMS_FAILURE, payload: undefined };
}

// T: Array of (usually) array, Z: target type, f: Maps T to Z[]
function flatMap<T, Z>(arr: T[], f: (e: T) => Z[]): Z[] {
  const concat = (x: Z[], y: Z[]): Z[] => x.concat(y);
  return arr.map(f).reduce(concat, []);
}

interface ItemsState {
  list: DecoratedItem[];
  rankedList: RankedItem[];
  total: number;
  totalPages: number;
  hasError: boolean;
  loading: boolean;
}

export type { ItemsState };
