import { AdTypes, Orders } from '@sbt-web/networking';

export const adTypesToSlugMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'vendita',
  [AdTypes.Rent]: 'affitto',
  [AdTypes.Seek]: 'cerco',
  [AdTypes.VacationRent]: 'affitto-vacanze',
  [AdTypes.GiveAway]: 'regalo',
};

export const slugToAdTypeMap: Record<string, AdTypes> = {
  vendita: AdTypes.Sell,
  affitto: AdTypes.Rent,
  cerco: AdTypes.Seek,
  'affitto-vacanze': AdTypes.VacationRent,
  regalo: AdTypes.GiveAway,
};

export const adTypesLabelsMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'In vendita',
  [AdTypes.Seek]: 'Cercasi',
  [AdTypes.Rent]: 'In affitto',
  [AdTypes.VacationRent]: 'In affitto per vacanze',
  [AdTypes.GiveAway]: 'In regalo',
};

export const adTypesShortLabelsMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'Vendita',
  [AdTypes.Seek]: 'Cercasi',
  [AdTypes.Rent]: 'Affitto',
  [AdTypes.VacationRent]: 'Affitto',
  [AdTypes.GiveAway]: 'Regalo',
};

export const ordersFullLabelsMap: Record<Orders, string> = {
  [Orders.Relevance]: 'Dal più rilevante',
  [Orders.DateDesc]: 'Dal più recente',
  [Orders.PriceAsc]: 'Dal meno caro',
  [Orders.PriceDesc]: 'Dal più caro',
  [Orders.YearAsc]: 'Per anno crescente',
  [Orders.YearDesc]: 'Per anno decrescente',
  [Orders.MileageAsc]: 'Con meno chilometri',
  [Orders.MileageDesc]: 'Con più chilometri',
};

export enum FilterURI {
  Type = '/type',
  Query = '/query/search_text',
  QSO = '/query/subject_only',
  Category = '/category',
  Price = '/price',
  QualitySeal = '/quality_seal',
  Geo = '/geo',
  Zone = '/geo/zone',
  AdvertiserType = '/advertiser_type',
  Mileage = '/mileage',
  Year = '/year',
  Fuel = '/fuel',
  Gearbox = '/gearbox',
  Size = '/size',
  Room = '/room',
  Order = '/order',
  Page = '/page',
  ItemShippable = '/item_shippable',
  ItemUrgent = '/urgent',
  RadiusLongitude = '/radius/longitude',
  RadiusLatitude = '/radius/latitude',
  RadiusDistance = '/radius/distance',
  Gender = '/clothing_gender',
  FashionClothingType = '/fashion/clothing_type',
  FashionClothingBrand = '/fashion/brand',
  FashionSize = '/fashion/size',
  CarBrand = '/car/brand',
  BikeBrand = '/bike/brand',
  CarModel = '/car/model',
  CarType = '/car_type',
}

export const QSOLabel = 'Cerca solo nel titolo';

export const shippableOnlyLabel = 'Solo annunci con spedizione disponibile';
export const urgentLabel = 'Solo annunci urgenti';

export enum AdvertiserType {
  Private = 0,
  ImpresaPiu = 1,
  Company = 2,
}
