import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

type AdsGAMPlacementProps = {
  id: string;
  classes?: Array<string>;
  fixedHeight?: number;
  displayPlaceholder?: boolean;
};

/**
 * This component adds a wrapper to the div of the adUnit.
 * This wrapper has the role of "sandbox" because avoid too tall banners, when a
 * `fixedHeight` is provided.
 * The `fixedHeight` can be used for the placements above the fold to avoid
 * CLS issues.
 * It also fixes the centering of the creative.
 */
const AdsGAMPlacement = ({
  id,
  classes,
  fixedHeight,
  displayPlaceholder,
}: AdsGAMPlacementProps) => (
  <div
    className={cn(styles['gam-sandbox'], classes, {
      [styles['gam-sandbox--overflow-h']]: fixedHeight,
      [styles['gam-sandbox--with-placeholder']]: displayPlaceholder,
    })}
    style={{
      ...(fixedHeight ? { height: `${fixedHeight}px` } : {}),
      ...(displayPlaceholder
        ? {
            backgroundImage: `url('${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/reclame/reclame-logo.webp')`,
          }
        : {}),
    }}
  >
    <div id={id} />
  </div>
);

export { AdsGAMPlacement };
