import { AdItem } from '@sbt-web/networking';
import { GeoboostItem, Item } from '@shared/models';
import { ListingPlugin } from '@shared/models/ListingPlugins';

const isAdItem = (item: Item | ListingPlugin): item is AdItem =>
  'kind' in item && item.kind === 'AdItem';

export type RankedItem = {
  urn: string;
  kind: 'GalleryItem' | 'AdItem' | 'GeoboostItem';
};

const getRankedUrnForGeoboost = (boostedItems: Array<AdItem>): string =>
  boostedItems.map((adItem) => adItem.urn).join(',');

const getRankedItems = (
  items: Array<AdItem | ListingPlugin>
): Array<RankedItem> => {
  const eligibleRanked: Array<RankedItem> = [];
  items.forEach((item) => {
    if (isAdItem(item)) {
      eligibleRanked.push({ kind: item.kind, urn: item.urn });
    } else if (
      item.model.kind === 'GeoboostItem' &&
      (item.model as GeoboostItem).boostedItems.length
    ) {
      eligibleRanked.push({
        kind: item.model.kind,
        urn: getRankedUrnForGeoboost((item.model as GeoboostItem).boostedItems),
      });
    } else if (item.model.kind === 'GalleryItem') {
      eligibleRanked.push({
        kind: item.model.kind,
        urn: item.model.adItem.urn,
      });
    }
  });
  return eligibleRanked;
};

export { getRankedItems, getRankedUrnForGeoboost };
