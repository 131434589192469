import React, { type PropsWithChildren } from 'react';
import { AdsGAMPlacement } from '@client/components/Adv/GPT';
import styles from './index.module.scss';

interface Props {
  skyscraperLeft?: {
    id: string;
    enabled: boolean;
  };
  skyscraperRight?: {
    id: string;
    enabled: boolean;
  };
  billboard?: {
    id: string;
    enabled: boolean;
  };
}

const SkeletonWithAdv = ({
  children,
  skyscraperLeft,
  skyscraperRight,
  billboard,
}: Props & PropsWithChildren) => {
  return (
    <div className={styles['skeleton']}>
      {skyscraperLeft?.enabled ? (
        <aside className={styles['left-aside']}>
          <div className={styles['aside-wrapper']}>
            <div className={styles['aside-skyscraper']}>
              <div id={skyscraperLeft.id} />
            </div>
          </div>
        </aside>
      ) : null}

      <div className={styles['main']}>
        {billboard?.enabled ? (
          <AdsGAMPlacement
            classes={[styles['billboard']]}
            id={billboard.id}
            fixedHeight={250}
            displayPlaceholder={true}
          />
        ) : null}
        {children}
      </div>

      {skyscraperRight?.enabled ? (
        <aside className={styles['right-aside']}>
          <div className={styles['aside-wrapper']}>
            <div className={styles['aside-skyscraper']}>
              <div id={skyscraperRight.id} />
            </div>
          </div>
        </aside>
      ) : null}
    </div>
  );
};

export { SkeletonWithAdv };
