import type { CompositeFilter, SerializableFilter } from '@shared/models';

interface FilterStore {
  version: '1.0';
  configurations: Record<
    string,
    { filters: CompositeFilter[]; sparseFilters: SerializableFilter[] }
  >;
}

const store: FilterStore = {
  version: '1.0',
  configurations: {
    '1_s': {
      filters: [
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/1/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/1/adtypes/s/prices',
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/1/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/1/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/1/adtypes/s/prices',
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/1/advertiser_types',
        },
      ],
    },
    '1_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/1/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/1/advertiser_types',
        },
      ],
    },
    '2_s': {
      filters: [
        {
          uri: '/vehicle_status',
          label: 'Tipo di veicolo',
          type: 'list',
          queryString: 'cvs',
          dataSource: '/v1/values/vehicle_status',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/2/advertiser_types',
        },
        {
          uri: '',
          label: '',
          type: 'linked',
          filters: [
            {
              uri: '/car/brand',
              label: 'Marca',
              type: 'list',
              queryString: 'cb',
              dataSource: '/v1/values/cars/brands',
            },
            {
              uri: '/car/model',
              label: 'Modello',
              type: 'list',
              queryString: 'cm',
              dataSource: '/v1/values/cars/brands/{/car/brand}/metamodels',
            },
          ],
        },
        {
          uri: '/quality_seal_flag',
          label: 'Certificato di qualità',
          type: 'bool',
          queryString: 'qltsf',
          sublabel: 'Solo auto certificate',
        },
        {
          uri: '/year',
          label: 'Anno di immatricolazione',
          type: 'range',
          min: {
            uri: '/year/min',
            label: 'da',
            type: 'list',
            queryString: 'ys',
            dataSource: '/v1/values/years',
          },
          max: {
            uri: '/year/max',
            label: 'a',
            type: 'list',
            queryString: 'ye',
            dataSource: '/v1/values/years',
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/2/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/2/adtypes/s/prices',
          },
        },
        {
          uri: '/mileage',
          label: 'Km',
          type: 'range',
          min: {
            uri: '/mileage/min',
            label: 'da',
            type: 'list',
            queryString: 'ms',
            dataSource: '/v1/values/mileage/min',
          },
          max: {
            uri: '/mileage/max',
            label: 'a',
            type: 'list',
            queryString: 'me',
            dataSource: '/v1/values/mileage/max',
          },
        },
        {
          uri: '/car_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ct',
          dataSource: '/v1/values/cars/types',
        },
        {
          uri: '/fuel',
          label: 'Carburante',
          type: 'list',
          queryString: 'fl',
          dataSource: '/v1/values/fuels/types',
        },
        {
          uri: '/gearbox',
          label: 'Cambio',
          type: 'list',
          queryString: 'gr',
          dataSource: '/v1/values/gears/types',
        },
        {
          uri: '/seats',
          label: 'Posti',
          type: 'range',
          min: {
            uri: '/seats/min',
            label: 'da',
            type: 'list',
            queryString: 'ss',
            dataSource: '/v1/values/seats',
          },
          max: {
            uri: '/seats/max',
            label: 'a',
            type: 'list',
            queryString: 'se',
            dataSource: '/v1/values/seats',
          },
        },
        {
          uri: '/doors',
          label: 'Porte',
          type: 'list',
          queryString: 'dr',
          dataSource: '/v1/values/doors',
        },
        {
          uri: '/color',
          label: 'Colore',
          type: 'list',
          queryString: 'cl',
          dataSource: '/v1/values/colors/types',
        },
        {
          uri: '/pollution',
          label: 'Classe emissioni',
          type: 'list',
          queryString: 'pl',
          dataSource: '/v1/values/pollution/types',
        },
        {
          uri: '/vat_deductible',
          label: 'Iva esposta',
          type: 'bool',
          queryString: 'vatd',
          sublabel: 'IVA esposta',
        },
      ],
      sparseFilters: [
        {
          uri: '/vehicle_status',
          label: 'Tipo di veicolo',
          type: 'list',
          queryString: 'cvs',
          dataSource: '/v1/values/vehicle_status',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/2/advertiser_types',
        },
        {
          uri: '/car/brand',
          label: 'Marca',
          type: 'list',
          queryString: 'cb',
          dataSource: '/v1/values/cars/brands',
        },
        {
          uri: '/car/model',
          label: 'Modello',
          type: 'list',
          queryString: 'cm',
          dataSource: '/v1/values/cars/brands/{/car/brand}/metamodels',
        },
        {
          uri: '/quality_seal_flag',
          label: 'Certificato di qualità',
          type: 'bool',
          queryString: 'qltsf',
          sublabel: 'Solo auto certificate',
        },
        {
          uri: '/year/min',
          label: 'da',
          type: 'list',
          queryString: 'ys',
          dataSource: '/v1/values/years',
        },
        {
          uri: '/year/max',
          label: 'a',
          type: 'list',
          queryString: 'ye',
          dataSource: '/v1/values/years',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/2/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/2/adtypes/s/prices',
        },
        {
          uri: '/mileage/min',
          label: 'da',
          type: 'list',
          queryString: 'ms',
          dataSource: '/v1/values/mileage/min',
        },
        {
          uri: '/mileage/max',
          label: 'a',
          type: 'list',
          queryString: 'me',
          dataSource: '/v1/values/mileage/max',
        },
        {
          uri: '/car_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ct',
          dataSource: '/v1/values/cars/types',
        },
        {
          uri: '/fuel',
          label: 'Carburante',
          type: 'list',
          queryString: 'fl',
          dataSource: '/v1/values/fuels/types',
        },
        {
          uri: '/gearbox',
          label: 'Cambio',
          type: 'list',
          queryString: 'gr',
          dataSource: '/v1/values/gears/types',
        },
        {
          uri: '/seats/min',
          label: 'da',
          type: 'list',
          queryString: 'ss',
          dataSource: '/v1/values/seats',
        },
        {
          uri: '/seats/max',
          label: 'a',
          type: 'list',
          queryString: 'se',
          dataSource: '/v1/values/seats',
        },
        {
          uri: '/doors',
          label: 'Porte',
          type: 'list',
          queryString: 'dr',
          dataSource: '/v1/values/doors',
        },
        {
          uri: '/color',
          label: 'Colore',
          type: 'list',
          queryString: 'cl',
          dataSource: '/v1/values/colors/types',
        },
        {
          uri: '/pollution',
          label: 'Classe emissioni',
          type: 'list',
          queryString: 'pl',
          dataSource: '/v1/values/pollution/types',
        },
        {
          uri: '/vat_deductible',
          label: 'Iva esposta',
          type: 'bool',
          queryString: 'vatd',
          sublabel: 'IVA esposta',
        },
      ],
    },
    '2_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/2/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/2/advertiser_types',
        },
      ],
    },
    '5_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/5/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/5/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/5/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/5/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/5/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/5/adtypes/s/prices',
        },
      ],
    },
    '5_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/5/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/5/advertiser_types',
        },
      ],
    },
    '5_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '3_s': {
      filters: [
        {
          uri: '/vehicle_status',
          label: 'Tipo di veicolo',
          type: 'list',
          queryString: 'cvs',
          dataSource: '/v1/values/vehicle_status',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/3/advertiser_types',
        },
        {
          uri: '',
          label: '',
          type: 'linked',
          filters: [
            {
              uri: '/bike/brand',
              label: 'Marca',
              type: 'list',
              queryString: 'bb',
              dataSource: '/v1/values/motorbikes/brands',
            },
            {
              uri: '/bike/model',
              label: 'Modello',
              type: 'list',
              queryString: 'bm',
              dataSource: '/v1/values/motorbikes/brands/{/bike/brand}/models',
            },
          ],
        },
        {
          uri: '/year',
          label: 'Anno di immatricolazione',
          type: 'range',
          min: {
            uri: '/year/min',
            label: 'da',
            type: 'list',
            queryString: 'ys',
            dataSource: '/v1/values/years',
          },
          max: {
            uri: '/year/max',
            label: 'a',
            type: 'list',
            queryString: 'ye',
            dataSource: '/v1/values/years',
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/3/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/3/adtypes/s/prices',
          },
        },
        {
          uri: '/mileage',
          label: 'Km',
          type: 'range',
          min: {
            uri: '/mileage/min',
            label: 'da',
            type: 'list',
            queryString: 'ms',
            dataSource: '/v1/values/mileage/min',
          },
          max: {
            uri: '/mileage/max',
            label: 'a',
            type: 'list',
            queryString: 'me',
            dataSource: '/v1/values/mileage/max',
          },
        },
        {
          uri: '/motorbicycle_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'mt',
          dataSource: '/v1/values/motorbikes/types',
        },
        {
          uri: '/cubic_capacity',
          label: 'Cilindrata (cc)',
          type: 'range',
          min: {
            uri: '/cubic_capacity/min',
            label: 'da',
            type: 'list',
            queryString: 'ccs',
            dataSource: '/v1/values/cubic-capacity/types',
          },
          max: {
            uri: '/cubic_capacity/max',
            label: 'a',
            type: 'list',
            queryString: 'cce',
            dataSource: '/v1/values/cubic-capacity/types',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/vehicle_status',
          label: 'Tipo di veicolo',
          type: 'list',
          queryString: 'cvs',
          dataSource: '/v1/values/vehicle_status',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/3/advertiser_types',
        },
        {
          uri: '/bike/brand',
          label: 'Marca',
          type: 'list',
          queryString: 'bb',
          dataSource: '/v1/values/motorbikes/brands',
        },
        {
          uri: '/bike/model',
          label: 'Modello',
          type: 'list',
          queryString: 'bm',
          dataSource: '/v1/values/motorbikes/brands/{/bike/brand}/models',
        },
        {
          uri: '/year/min',
          label: 'da',
          type: 'list',
          queryString: 'ys',
          dataSource: '/v1/values/years',
        },
        {
          uri: '/year/max',
          label: 'a',
          type: 'list',
          queryString: 'ye',
          dataSource: '/v1/values/years',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/3/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/3/adtypes/s/prices',
        },
        {
          uri: '/mileage/min',
          label: 'da',
          type: 'list',
          queryString: 'ms',
          dataSource: '/v1/values/mileage/min',
        },
        {
          uri: '/mileage/max',
          label: 'a',
          type: 'list',
          queryString: 'me',
          dataSource: '/v1/values/mileage/max',
        },
        {
          uri: '/motorbicycle_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'mt',
          dataSource: '/v1/values/motorbikes/types',
        },
        {
          uri: '/cubic_capacity/min',
          label: 'da',
          type: 'list',
          queryString: 'ccs',
          dataSource: '/v1/values/cubic-capacity/types',
        },
        {
          uri: '/cubic_capacity/max',
          label: 'a',
          type: 'list',
          queryString: 'cce',
          dataSource: '/v1/values/cubic-capacity/types',
        },
      ],
    },
    '3_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/3/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/3/advertiser_types',
        },
      ],
    },
    '36_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/36/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/36/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/36/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/36/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/36/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/36/adtypes/s/prices',
        },
      ],
    },
    '36_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/36/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/36/advertiser_types',
        },
      ],
    },
    '36_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '22_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/22/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/22/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/22/adtypes/s/prices',
          },
        },
        {
          uri: '/ship_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bt',
          dataSource: '/v1/values/ships/types',
        },
        {
          uri: '/ship_length',
          label: 'Lunghezza (m)',
          type: 'range',
          min: {
            uri: '/ship_length/min',
            label: 'da',
            type: 'list',
            queryString: 'lns',
            dataSource: '/v1/values/ships/length',
          },
          max: {
            uri: '/ship_length/max',
            label: 'a',
            type: 'list',
            queryString: 'lne',
            dataSource: '/v1/values/ships/length',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/22/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/22/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/22/adtypes/s/prices',
        },
        {
          uri: '/ship_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bt',
          dataSource: '/v1/values/ships/types',
        },
        {
          uri: '/ship_length/min',
          label: 'da',
          type: 'list',
          queryString: 'lns',
          dataSource: '/v1/values/ships/length',
        },
        {
          uri: '/ship_length/max',
          label: 'a',
          type: 'list',
          queryString: 'lne',
          dataSource: '/v1/values/ships/length',
        },
      ],
    },
    '22_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/22/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/22/advertiser_types',
        },
      ],
    },
    '22_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/22/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Affitto giornaliero (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/22/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/22/adtypes/u/prices',
          },
        },
        {
          uri: '/ship_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bt',
          dataSource: '/v1/values/ships/types',
        },
        {
          uri: '/ship_length',
          label: 'Lunghezza (m)',
          type: 'range',
          min: {
            uri: '/ship_length/min',
            label: 'da',
            type: 'list',
            queryString: 'lns',
            dataSource: '/v1/values/ships/length',
          },
          max: {
            uri: '/ship_length/max',
            label: 'a',
            type: 'list',
            queryString: 'lne',
            dataSource: '/v1/values/ships/length',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/22/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/22/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/22/adtypes/u/prices',
        },
        {
          uri: '/ship_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bt',
          dataSource: '/v1/values/ships/types',
        },
        {
          uri: '/ship_length/min',
          label: 'da',
          type: 'list',
          queryString: 'lns',
          dataSource: '/v1/values/ships/length',
        },
        {
          uri: '/ship_length/max',
          label: 'a',
          type: 'list',
          queryString: 'lne',
          dataSource: '/v1/values/ships/length',
        },
      ],
    },
    '34_s': {
      filters: [
        {
          uri: '/vehicle_status',
          label: 'Tipo di veicolo',
          type: 'list',
          queryString: 'cvs',
          dataSource: '/v1/values/vehicle_status',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/34/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/34/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/34/adtypes/s/prices',
          },
        },
        {
          uri: '/caravan_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'crt',
          dataSource: '/v1/values/caravans/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/vehicle_status',
          label: 'Tipo di veicolo',
          type: 'list',
          queryString: 'cvs',
          dataSource: '/v1/values/vehicle_status',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/34/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/34/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/34/adtypes/s/prices',
        },
        {
          uri: '/caravan_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'crt',
          dataSource: '/v1/values/caravans/types',
        },
      ],
    },
    '34_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/34/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/34/advertiser_types',
        },
      ],
    },
    '34_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/34/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Affitto giornaliero (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/34/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/34/adtypes/u/prices',
          },
        },
        {
          uri: '/caravan_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'crt',
          dataSource: '/v1/values/caravans/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/34/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/34/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/34/adtypes/u/prices',
        },
        {
          uri: '/caravan_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'crt',
          dataSource: '/v1/values/caravans/types',
        },
      ],
    },
    '4_s': {
      filters: [
        {
          uri: '/vehicle_status',
          label: 'Tipo di veicolo',
          type: 'list',
          queryString: 'cvs',
          dataSource: '/v1/values/vehicle_status',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/4/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/4/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/4/adtypes/s/prices',
          },
        },
        {
          uri: '/vehicle_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'cvt',
          dataSource: '/v1/values/vehicles/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/vehicle_status',
          label: 'Tipo di veicolo',
          type: 'list',
          queryString: 'cvs',
          dataSource: '/v1/values/vehicle_status',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/4/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/4/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/4/adtypes/s/prices',
        },
        {
          uri: '/vehicle_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'cvt',
          dataSource: '/v1/values/vehicles/types',
        },
      ],
    },
    '4_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/4/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/4/advertiser_types',
        },
      ],
    },
    '6_s': {
      filters: [
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/6/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/6/adtypes/s/prices',
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/6/advertiser_types',
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/6/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/6/sizes',
          },
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/6/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/6/adtypes/s/prices',
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/6/advertiser_types',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/6/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/6/sizes',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '6_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/6/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/6/advertiser_types',
        },
      ],
    },
    '6_u': {
      filters: [
        {
          uri: '/price',
          label: 'Affitto mensile (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/6/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/6/adtypes/u/prices',
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/6/advertiser_types',
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/6/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/6/sizes',
          },
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/6/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/6/adtypes/u/prices',
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/6/advertiser_types',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/6/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/6/sizes',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '6_h': {
      filters: [
        {
          uri: '/price',
          label: 'Affitto settimanale (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/6/adtypes/h/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/6/adtypes/h/prices',
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/6/advertiser_types',
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/6/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/6/sizes',
          },
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
      ],
      sparseFilters: [
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/6/adtypes/h/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/6/adtypes/h/prices',
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/6/advertiser_types',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/6/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/6/sizes',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
      ],
    },
    '7_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/7/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/7/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/7/adtypes/s/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/7/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/7/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
        {
          uri: '/floor',
          label: 'Piano',
          type: 'range',
          min: {
            uri: '/floor/min',
            label: 'da',
            type: 'list',
            queryString: 'fls',
            dataSource: '/v1/values/floors/types',
          },
          max: {
            uri: '/floor/max',
            label: 'a',
            type: 'list',
            queryString: 'fle',
            dataSource: '/v1/values/floors/types',
          },
        },
        {
          uri: '/bathrooms',
          label: 'Bagni',
          type: 'range',
          min: {
            uri: '/bathrooms/min',
            label: 'da',
            type: 'list',
            queryString: 'btrs',
            dataSource: '/v1/values/bathrooms/types',
          },
          max: {
            uri: '/bathrooms/max',
            label: 'a',
            type: 'list',
            queryString: 'btre',
            dataSource: '/v1/values/bathrooms/types',
          },
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/balcony',
          label: 'Balcone',
          type: 'bool',
          queryString: 'blc',
          sublabel: 'Balcone',
        },
        {
          uri: '/elevator',
          label: 'Ascensore',
          type: 'bool',
          queryString: 'el',
          sublabel: 'Ascensore',
        },
        {
          uri: '/last_floor',
          label: 'Ultimo piano',
          type: 'bool',
          queryString: 'lflr',
          sublabel: 'Ultimo piano',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class',
          label: 'Classe energetica',
          type: 'range',
          min: {
            uri: '/energy_class/min',
            label: 'da',
            type: 'list',
            queryString: 'ecs',
            dataSource: '/v1/values/energy-class/types',
          },
          max: {
            uri: '/energy_class/max',
            label: 'a',
            type: 'list',
            queryString: 'ece',
            dataSource: '/v1/values/energy-class/types',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/7/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/7/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/7/adtypes/s/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/7/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/7/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/floor/min',
          label: 'da',
          type: 'list',
          queryString: 'fls',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/floor/max',
          label: 'a',
          type: 'list',
          queryString: 'fle',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/bathrooms/min',
          label: 'da',
          type: 'list',
          queryString: 'btrs',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/bathrooms/max',
          label: 'a',
          type: 'list',
          queryString: 'btre',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/balcony',
          label: 'Balcone',
          type: 'bool',
          queryString: 'blc',
          sublabel: 'Balcone',
        },
        {
          uri: '/elevator',
          label: 'Ascensore',
          type: 'bool',
          queryString: 'el',
          sublabel: 'Ascensore',
        },
        {
          uri: '/last_floor',
          label: 'Ultimo piano',
          type: 'bool',
          queryString: 'lflr',
          sublabel: 'Ultimo piano',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class/min',
          label: 'da',
          type: 'list',
          queryString: 'ecs',
          dataSource: '/v1/values/energy-class/types',
        },
        {
          uri: '/energy_class/max',
          label: 'a',
          type: 'list',
          queryString: 'ece',
          dataSource: '/v1/values/energy-class/types',
        },
      ],
    },
    '7_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/7/advertiser_types',
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/7/advertiser_types',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
      ],
    },
    '7_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/7/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Affitto mensile (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/7/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/7/adtypes/u/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/7/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/7/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
        {
          uri: '/floor',
          label: 'Piano',
          type: 'range',
          min: {
            uri: '/floor/min',
            label: 'da',
            type: 'list',
            queryString: 'fls',
            dataSource: '/v1/values/floors/types',
          },
          max: {
            uri: '/floor/max',
            label: 'a',
            type: 'list',
            queryString: 'fle',
            dataSource: '/v1/values/floors/types',
          },
        },
        {
          uri: '/bathrooms',
          label: 'Bagni',
          type: 'range',
          min: {
            uri: '/bathrooms/min',
            label: 'da',
            type: 'list',
            queryString: 'btrs',
            dataSource: '/v1/values/bathrooms/types',
          },
          max: {
            uri: '/bathrooms/max',
            label: 'a',
            type: 'list',
            queryString: 'btre',
            dataSource: '/v1/values/bathrooms/types',
          },
        },
        {
          uri: '/furnished',
          label: 'Arredato',
          type: 'bool',
          queryString: 'fr',
          sublabel: 'Arredato',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/balcony',
          label: 'Balcone',
          type: 'bool',
          queryString: 'blc',
          sublabel: 'Balcone',
        },
        {
          uri: '/elevator',
          label: 'Ascensore',
          type: 'bool',
          queryString: 'el',
          sublabel: 'Ascensore',
        },
        {
          uri: '/last_floor',
          label: 'Ultimo piano',
          type: 'bool',
          queryString: 'lflr',
          sublabel: 'Ultimo piano',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class',
          label: 'Classe energetica',
          type: 'range',
          min: {
            uri: '/energy_class/min',
            label: 'da',
            type: 'list',
            queryString: 'ecs',
            dataSource: '/v1/values/energy-class/types',
          },
          max: {
            uri: '/energy_class/max',
            label: 'a',
            type: 'list',
            queryString: 'ece',
            dataSource: '/v1/values/energy-class/types',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/7/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/7/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/7/adtypes/u/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/7/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/7/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/floor/min',
          label: 'da',
          type: 'list',
          queryString: 'fls',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/floor/max',
          label: 'a',
          type: 'list',
          queryString: 'fle',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/bathrooms/min',
          label: 'da',
          type: 'list',
          queryString: 'btrs',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/bathrooms/max',
          label: 'a',
          type: 'list',
          queryString: 'btre',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/furnished',
          label: 'Arredato',
          type: 'bool',
          queryString: 'fr',
          sublabel: 'Arredato',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/balcony',
          label: 'Balcone',
          type: 'bool',
          queryString: 'blc',
          sublabel: 'Balcone',
        },
        {
          uri: '/elevator',
          label: 'Ascensore',
          type: 'bool',
          queryString: 'el',
          sublabel: 'Ascensore',
        },
        {
          uri: '/last_floor',
          label: 'Ultimo piano',
          type: 'bool',
          queryString: 'lflr',
          sublabel: 'Ultimo piano',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class/min',
          label: 'da',
          type: 'list',
          queryString: 'ecs',
          dataSource: '/v1/values/energy-class/types',
        },
        {
          uri: '/energy_class/max',
          label: 'a',
          type: 'list',
          queryString: 'ece',
          dataSource: '/v1/values/energy-class/types',
        },
      ],
    },
    '43_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/43/advertiser_types',
        },
        {
          uri: '/room_type',
          label: 'Tipo di stanza',
          type: 'list',
          queryString: 'rt',
          dataSource: '/v1/values/rooms/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/43/advertiser_types',
        },
        {
          uri: '/room_type',
          label: 'Tipo di stanza',
          type: 'list',
          queryString: 'rt',
          dataSource: '/v1/values/rooms/types',
        },
      ],
    },
    '43_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/43/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Affitto mensile (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/43/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/43/adtypes/u/prices',
          },
        },
        {
          uri: '/room_type',
          label: 'Tipo di stanza',
          type: 'list',
          queryString: 'rt',
          dataSource: '/v1/values/rooms/types',
        },
        {
          uri: '/gender',
          label: 'Affitta a',
          type: 'list',
          queryString: 'gndr',
          dataSource: '/v1/values/rooms/genders',
        },
        {
          uri: '/smoker',
          label: 'Fumatori',
          type: 'list',
          queryString: 'smk',
          dataSource: '/v1/values/smokers/types',
        },
        {
          uri: '/floor',
          label: 'Piano',
          type: 'range',
          min: {
            uri: '/floor/min',
            label: 'da',
            type: 'list',
            queryString: 'fls',
            dataSource: '/v1/values/floors/types',
          },
          max: {
            uri: '/floor/max',
            label: 'a',
            type: 'list',
            queryString: 'fle',
            dataSource: '/v1/values/floors/types',
          },
        },
        {
          uri: '/furnished',
          label: 'Arredato',
          type: 'bool',
          queryString: 'fr',
          sublabel: 'Arredato',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/elevator',
          label: 'Ascensore',
          type: 'bool',
          queryString: 'el',
          sublabel: 'Ascensore',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/43/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/43/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/43/adtypes/u/prices',
        },
        {
          uri: '/room_type',
          label: 'Tipo di stanza',
          type: 'list',
          queryString: 'rt',
          dataSource: '/v1/values/rooms/types',
        },
        {
          uri: '/gender',
          label: 'Affitta a',
          type: 'list',
          queryString: 'gndr',
          dataSource: '/v1/values/rooms/genders',
        },
        {
          uri: '/smoker',
          label: 'Fumatori',
          type: 'list',
          queryString: 'smk',
          dataSource: '/v1/values/smokers/types',
        },
        {
          uri: '/floor/min',
          label: 'da',
          type: 'list',
          queryString: 'fls',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/floor/max',
          label: 'a',
          type: 'list',
          queryString: 'fle',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/furnished',
          label: 'Arredato',
          type: 'bool',
          queryString: 'fr',
          sublabel: 'Arredato',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/elevator',
          label: 'Ascensore',
          type: 'bool',
          queryString: 'el',
          sublabel: 'Ascensore',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
      ],
    },
    '29_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/29/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/29/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/29/adtypes/s/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/29/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/29/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
        {
          uri: '/multi_level',
          label: 'Proprietà su più livelli',
          type: 'bool',
          queryString: 'mlv',
          sublabel: 'Proprietà su più livelli',
        },
        {
          uri: '/bathrooms',
          label: 'Bagni',
          type: 'range',
          min: {
            uri: '/bathrooms/min',
            label: 'da',
            type: 'list',
            queryString: 'btrs',
            dataSource: '/v1/values/bathrooms/types',
          },
          max: {
            uri: '/bathrooms/max',
            label: 'a',
            type: 'list',
            queryString: 'btre',
            dataSource: '/v1/values/bathrooms/types',
          },
        },
        {
          uri: '/balcony',
          label: 'Balcone',
          type: 'bool',
          queryString: 'blc',
          sublabel: 'Balcone',
        },
        {
          uri: '/garden',
          label: 'Giardino',
          type: 'bool',
          queryString: 'grd',
          sublabel: 'Giardino',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class',
          label: 'Classe energetica',
          type: 'range',
          min: {
            uri: '/energy_class/min',
            label: 'da',
            type: 'list',
            queryString: 'ecs',
            dataSource: '/v1/values/energy-class/types',
          },
          max: {
            uri: '/energy_class/max',
            label: 'a',
            type: 'list',
            queryString: 'ece',
            dataSource: '/v1/values/energy-class/types',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/29/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/29/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/29/adtypes/s/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/29/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/29/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/multi_level',
          label: 'Proprietà su più livelli',
          type: 'bool',
          queryString: 'mlv',
          sublabel: 'Proprietà su più livelli',
        },
        {
          uri: '/bathrooms/min',
          label: 'da',
          type: 'list',
          queryString: 'btrs',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/bathrooms/max',
          label: 'a',
          type: 'list',
          queryString: 'btre',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/balcony',
          label: 'Balcone',
          type: 'bool',
          queryString: 'blc',
          sublabel: 'Balcone',
        },
        {
          uri: '/garden',
          label: 'Giardino',
          type: 'bool',
          queryString: 'grd',
          sublabel: 'Giardino',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class/min',
          label: 'da',
          type: 'list',
          queryString: 'ecs',
          dataSource: '/v1/values/energy-class/types',
        },
        {
          uri: '/energy_class/max',
          label: 'a',
          type: 'list',
          queryString: 'ece',
          dataSource: '/v1/values/energy-class/types',
        },
      ],
    },
    '29_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/29/advertiser_types',
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/29/advertiser_types',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
      ],
    },
    '29_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/29/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Affitto mensile (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/29/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/29/adtypes/u/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/29/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/29/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/29/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/29/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/29/adtypes/u/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/29/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/29/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
      ],
    },
    '30_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/30/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/30/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/30/adtypes/s/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/30/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/30/sizes',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/30/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/30/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/30/adtypes/s/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/30/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/30/sizes',
        },
      ],
    },
    '30_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/30/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/30/advertiser_types',
        },
      ],
    },
    '30_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/30/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Affitto mensile (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/30/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/30/adtypes/u/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/30/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/30/sizes',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/30/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/30/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/30/adtypes/u/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/30/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/30/sizes',
        },
      ],
    },
    '31_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/31/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/31/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/31/adtypes/s/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/31/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/31/sizes',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/31/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/31/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/31/adtypes/s/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/31/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/31/sizes',
        },
      ],
    },
    '31_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/31/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/31/advertiser_types',
        },
      ],
    },
    '31_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/31/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Affitto mensile (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/31/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/31/adtypes/u/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/31/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/31/sizes',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/31/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/31/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/31/adtypes/u/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/31/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/31/sizes',
        },
      ],
    },
    '32_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/32/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/32/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/32/adtypes/s/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/32/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/32/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/32/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/32/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/32/adtypes/s/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/32/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/32/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
      ],
    },
    '32_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/32/advertiser_types',
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/32/advertiser_types',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
      ],
    },
    '32_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/32/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Affitto mensile (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/32/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/32/adtypes/u/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/32/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/32/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/32/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/32/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/32/adtypes/u/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/32/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/32/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
      ],
    },
    '33_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/33/advertiser_types',
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/33/advertiser_types',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
      ],
    },
    '33_h': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/33/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Affitto settimanale (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/33/adtypes/h/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/33/adtypes/h/prices',
          },
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/33/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/33/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
        {
          uri: '/floor',
          label: 'Piano',
          type: 'range',
          min: {
            uri: '/floor/min',
            label: 'da',
            type: 'list',
            queryString: 'fls',
            dataSource: '/v1/values/floors/types',
          },
          max: {
            uri: '/floor/max',
            label: 'a',
            type: 'list',
            queryString: 'fle',
            dataSource: '/v1/values/floors/types',
          },
        },
        {
          uri: '/bathrooms',
          label: 'Bagni',
          type: 'range',
          min: {
            uri: '/bathrooms/min',
            label: 'da',
            type: 'list',
            queryString: 'btrs',
            dataSource: '/v1/values/bathrooms/types',
          },
          max: {
            uri: '/bathrooms/max',
            label: 'a',
            type: 'list',
            queryString: 'btre',
            dataSource: '/v1/values/bathrooms/types',
          },
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/multi_level',
          label: 'Proprietà su più livelli',
          type: 'bool',
          queryString: 'mlv',
          sublabel: 'Proprietà su più livelli',
        },
        {
          uri: '/balcony',
          label: 'Balcone',
          type: 'bool',
          queryString: 'blc',
          sublabel: 'Balcone',
        },
        {
          uri: '/last_floor',
          label: 'Ultimo piano',
          type: 'bool',
          queryString: 'lflr',
          sublabel: 'Ultimo piano',
        },
        {
          uri: '/garden',
          label: 'Giardino',
          type: 'bool',
          queryString: 'grd',
          sublabel: 'Giardino',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/33/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/33/adtypes/h/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/33/adtypes/h/prices',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/33/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/33/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/floor/min',
          label: 'da',
          type: 'list',
          queryString: 'fls',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/floor/max',
          label: 'a',
          type: 'list',
          queryString: 'fle',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/bathrooms/min',
          label: 'da',
          type: 'list',
          queryString: 'btrs',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/bathrooms/max',
          label: 'a',
          type: 'list',
          queryString: 'btre',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/multi_level',
          label: 'Proprietà su più livelli',
          type: 'bool',
          queryString: 'mlv',
          sublabel: 'Proprietà su più livelli',
        },
        {
          uri: '/balcony',
          label: 'Balcone',
          type: 'bool',
          queryString: 'blc',
          sublabel: 'Balcone',
        },
        {
          uri: '/last_floor',
          label: 'Ultimo piano',
          type: 'bool',
          queryString: 'lflr',
          sublabel: 'Ultimo piano',
        },
        {
          uri: '/garden',
          label: 'Giardino',
          type: 'bool',
          queryString: 'grd',
          sublabel: 'Giardino',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
      ],
    },
    '8_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/8/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/8/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/8/adtypes/s/prices',
          },
        },
        {
          uri: '/office_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ot',
          dataSource: '/v1/values/offices/types',
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/8/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/8/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
        {
          uri: '/floor',
          label: 'Piano',
          type: 'range',
          min: {
            uri: '/floor/min',
            label: 'da',
            type: 'list',
            queryString: 'fls',
            dataSource: '/v1/values/floors/types',
          },
          max: {
            uri: '/floor/max',
            label: 'a',
            type: 'list',
            queryString: 'fle',
            dataSource: '/v1/values/floors/types',
          },
        },
        {
          uri: '/bathrooms',
          label: 'Bagni',
          type: 'range',
          min: {
            uri: '/bathrooms/min',
            label: 'da',
            type: 'list',
            queryString: 'btrs',
            dataSource: '/v1/values/bathrooms/types',
          },
          max: {
            uri: '/bathrooms/max',
            label: 'a',
            type: 'list',
            queryString: 'btre',
            dataSource: '/v1/values/bathrooms/types',
          },
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class',
          label: 'Classe energetica',
          type: 'range',
          min: {
            uri: '/energy_class/min',
            label: 'da',
            type: 'list',
            queryString: 'ecs',
            dataSource: '/v1/values/energy-class/types',
          },
          max: {
            uri: '/energy_class/max',
            label: 'a',
            type: 'list',
            queryString: 'ece',
            dataSource: '/v1/values/energy-class/types',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/8/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/8/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/8/adtypes/s/prices',
        },
        {
          uri: '/office_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ot',
          dataSource: '/v1/values/offices/types',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/8/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/8/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/floor/min',
          label: 'da',
          type: 'list',
          queryString: 'fls',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/floor/max',
          label: 'a',
          type: 'list',
          queryString: 'fle',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/bathrooms/min',
          label: 'da',
          type: 'list',
          queryString: 'btrs',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/bathrooms/max',
          label: 'a',
          type: 'list',
          queryString: 'btre',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class/min',
          label: 'da',
          type: 'list',
          queryString: 'ecs',
          dataSource: '/v1/values/energy-class/types',
        },
        {
          uri: '/energy_class/max',
          label: 'a',
          type: 'list',
          queryString: 'ece',
          dataSource: '/v1/values/energy-class/types',
        },
      ],
    },
    '8_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/8/advertiser_types',
        },
        {
          uri: '/office_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ot',
          dataSource: '/v1/values/offices/types',
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/8/advertiser_types',
        },
        {
          uri: '/office_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ot',
          dataSource: '/v1/values/offices/types',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
      ],
    },
    '8_u': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/8/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Affitto mensile (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/8/adtypes/u/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/8/adtypes/u/prices',
          },
        },
        {
          uri: '/office_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ot',
          dataSource: '/v1/values/offices/types',
        },
        {
          uri: '/size',
          label: 'Superficie (mq)',
          type: 'range',
          min: {
            uri: '/size/min',
            label: 'da',
            type: 'list',
            queryString: 'szs',
            dataSource: '/v1/values/categories/8/sizes',
          },
          max: {
            uri: '/size/max',
            label: 'a',
            type: 'list',
            queryString: 'sze',
            dataSource: '/v1/values/categories/8/sizes',
          },
        },
        {
          uri: '/room',
          label: 'Locali',
          type: 'range',
          min: {
            uri: '/room/min',
            label: 'da',
            type: 'list',
            queryString: 'rs',
            dataSource: '/v1/values/rooms',
          },
          max: {
            uri: '/room/max',
            label: 'a',
            type: 'list',
            queryString: 're',
            dataSource: '/v1/values/rooms',
          },
        },
        {
          uri: '/floor',
          label: 'Piano',
          type: 'range',
          min: {
            uri: '/floor/min',
            label: 'da',
            type: 'list',
            queryString: 'fls',
            dataSource: '/v1/values/floors/types',
          },
          max: {
            uri: '/floor/max',
            label: 'a',
            type: 'list',
            queryString: 'fle',
            dataSource: '/v1/values/floors/types',
          },
        },
        {
          uri: '/bathrooms',
          label: 'Bagni',
          type: 'range',
          min: {
            uri: '/bathrooms/min',
            label: 'da',
            type: 'list',
            queryString: 'btrs',
            dataSource: '/v1/values/bathrooms/types',
          },
          max: {
            uri: '/bathrooms/max',
            label: 'a',
            type: 'list',
            queryString: 'btre',
            dataSource: '/v1/values/bathrooms/types',
          },
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class',
          label: 'Classe energetica',
          type: 'range',
          min: {
            uri: '/energy_class/min',
            label: 'da',
            type: 'list',
            queryString: 'ecs',
            dataSource: '/v1/values/energy-class/types',
          },
          max: {
            uri: '/energy_class/max',
            label: 'a',
            type: 'list',
            queryString: 'ece',
            dataSource: '/v1/values/energy-class/types',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/8/advertiser_types',
        },
        {
          uri: '/building_condition',
          label: "Condizione dell'immobile",
          type: 'list',
          queryString: 'bc',
          dataSource: '/v1/values/building_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/8/adtypes/u/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/8/adtypes/u/prices',
        },
        {
          uri: '/office_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ot',
          dataSource: '/v1/values/offices/types',
        },
        {
          uri: '/size/min',
          label: 'da',
          type: 'list',
          queryString: 'szs',
          dataSource: '/v1/values/categories/8/sizes',
        },
        {
          uri: '/size/max',
          label: 'a',
          type: 'list',
          queryString: 'sze',
          dataSource: '/v1/values/categories/8/sizes',
        },
        {
          uri: '/room/min',
          label: 'da',
          type: 'list',
          queryString: 'rs',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/room/max',
          label: 'a',
          type: 'list',
          queryString: 're',
          dataSource: '/v1/values/rooms',
        },
        {
          uri: '/floor/min',
          label: 'da',
          type: 'list',
          queryString: 'fls',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/floor/max',
          label: 'a',
          type: 'list',
          queryString: 'fle',
          dataSource: '/v1/values/floors/types',
        },
        {
          uri: '/bathrooms/min',
          label: 'da',
          type: 'list',
          queryString: 'btrs',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/bathrooms/max',
          label: 'a',
          type: 'list',
          queryString: 'btre',
          dataSource: '/v1/values/bathrooms/types',
        },
        {
          uri: '/available_now',
          label: 'Disponibilità immediata',
          type: 'bool',
          queryString: 'an',
          sublabel: 'Libero subito',
        },
        {
          uri: '/parking',
          label: 'Parcheggio',
          type: 'list',
          queryString: 'prk',
          dataSource: '/v1/values/parkings/types',
          multiSelection: true,
        },
        {
          uri: '/heating',
          label: 'Riscaldamento',
          type: 'list',
          queryString: 'htg',
          dataSource: '/v1/values/heatings/types',
        },
        {
          uri: '/air_conditioning',
          label: 'Aria condizionata',
          type: 'bool',
          queryString: 'ac',
          sublabel: 'Aria condizionata',
        },
        {
          uri: '/energy_class/min',
          label: 'da',
          type: 'list',
          queryString: 'ecs',
          dataSource: '/v1/values/energy-class/types',
        },
        {
          uri: '/energy_class/max',
          label: 'a',
          type: 'list',
          queryString: 'ece',
          dataSource: '/v1/values/energy-class/types',
        },
      ],
    },
    '24_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/24/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/24/advertiser_types',
        },
      ],
    },
    '26_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/26/advertiser_types',
        },
        {
          uri: '/job_category',
          label: 'Settore',
          type: 'list',
          queryString: 'jc',
          dataSource: '/v1/values/jobs/jobcategories/long',
          multiSelection: true,
        },
        {
          uri: '/contract_type',
          label: 'Contratto',
          type: 'list',
          queryString: 'cnt',
          dataSource: '/v1/values/jobs/contracts',
        },
        {
          uri: '/work_hour',
          label: 'Orario di lavoro',
          type: 'list',
          queryString: 'wh',
          dataSource: '/v1/values/jobs/hours',
        },
        {
          uri: '/work_level',
          label: 'Inquadramento',
          type: 'list',
          queryString: 'wl',
          dataSource: '/v1/values/jobs/types',
        },
        {
          uri: '/degree',
          label: 'Titolo di studio',
          type: 'list',
          queryString: 'd',
          dataSource: '/v1/values/educational-degrees',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/26/advertiser_types',
        },
        {
          uri: '/job_category',
          label: 'Settore',
          type: 'list',
          queryString: 'jc',
          dataSource: '/v1/values/jobs/jobcategories/long',
          multiSelection: true,
        },
        {
          uri: '/contract_type',
          label: 'Contratto',
          type: 'list',
          queryString: 'cnt',
          dataSource: '/v1/values/jobs/contracts',
        },
        {
          uri: '/work_hour',
          label: 'Orario di lavoro',
          type: 'list',
          queryString: 'wh',
          dataSource: '/v1/values/jobs/hours',
        },
        {
          uri: '/work_level',
          label: 'Inquadramento',
          type: 'list',
          queryString: 'wl',
          dataSource: '/v1/values/jobs/types',
        },
        {
          uri: '/degree',
          label: 'Titolo di studio',
          type: 'list',
          queryString: 'd',
          dataSource: '/v1/values/educational-degrees',
        },
      ],
    },
    '50_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/50/advertiser_types',
        },
        {
          uri: '',
          label: '',
          type: 'linked',
          filters: [
            {
              uri: '/service/type',
              label: 'Tipologia',
              type: 'list',
              queryString: 'set',
              dataSource: '/v1/values/services/types',
            },
            {
              uri: '/service/name',
              label: 'Attività',
              type: 'list',
              queryString: 'sen',
              dataSource: '/v1/values/services/types/{/service/type}/names',
            },
          ],
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/50/advertiser_types',
        },
        {
          uri: '/service/type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'set',
          dataSource: '/v1/values/services/types',
        },
        {
          uri: '/service/name',
          label: 'Attività',
          type: 'list',
          queryString: 'sen',
          dataSource: '/v1/values/services/types/{/service/type}/names',
        },
      ],
    },
    '42_s': {
      filters: [
        {
          uri: '/job_category',
          label: 'Settore',
          type: 'list',
          queryString: 'jc',
          dataSource: '/v1/values/jobs/jobcategories/long',
          multiSelection: true,
        },
        {
          uri: '/contract_type',
          label: 'Contratto',
          type: 'list',
          queryString: 'cnt',
          dataSource: '/v1/values/jobs/contracts',
        },
        {
          uri: '/work_hour',
          label: 'Orario di lavoro',
          type: 'list',
          queryString: 'wh',
          dataSource: '/v1/values/jobs/hours',
        },
        {
          uri: '/degree',
          label: 'Titolo di studio',
          type: 'list',
          queryString: 'd',
          dataSource: '/v1/values/educational-degrees',
        },
      ],
      sparseFilters: [
        {
          uri: '/job_category',
          label: 'Settore',
          type: 'list',
          queryString: 'jc',
          dataSource: '/v1/values/jobs/jobcategories/long',
          multiSelection: true,
        },
        {
          uri: '/contract_type',
          label: 'Contratto',
          type: 'list',
          queryString: 'cnt',
          dataSource: '/v1/values/jobs/contracts',
        },
        {
          uri: '/work_hour',
          label: 'Orario di lavoro',
          type: 'list',
          queryString: 'wh',
          dataSource: '/v1/values/jobs/hours',
        },
        {
          uri: '/degree',
          label: 'Titolo di studio',
          type: 'list',
          queryString: 'd',
          dataSource: '/v1/values/educational-degrees',
        },
      ],
    },
    '25_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/25/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/25/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/25/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/25/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/25/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/25/adtypes/s/prices',
        },
      ],
    },
    '25_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/25/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/25/advertiser_types',
        },
      ],
    },
    '9_s': {
      filters: [
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/9/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/9/adtypes/s/prices',
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/9/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/9/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/9/adtypes/s/prices',
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/9/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '9_g': {
      filters: [],
      sparseFilters: [],
    },
    '9_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/9/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/9/advertiser_types',
        },
      ],
    },
    '10_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/10/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/10/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/10/adtypes/s/prices',
          },
        },
        {
          uri: '/computer_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'pt',
          dataSource: '/v1/values/pc/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/10/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/10/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/10/adtypes/s/prices',
        },
        {
          uri: '/computer_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'pt',
          dataSource: '/v1/values/pc/types',
        },
      ],
    },
    '10_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/computer_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'pt',
          dataSource: '/v1/values/pc/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/computer_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'pt',
          dataSource: '/v1/values/pc/types',
        },
      ],
    },
    '10_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/10/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/10/advertiser_types',
        },
      ],
    },
    '44_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/44/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/44/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/44/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/44/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/44/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/44/adtypes/s/prices',
        },
      ],
    },
    '44_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '44_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/44/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/44/advertiser_types',
        },
      ],
    },
    '11_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/11/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/11/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/11/adtypes/s/prices',
          },
        },
        {
          uri: '/audiovideo_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'avt',
          dataSource: '/v1/values/audio-video/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/11/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/11/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/11/adtypes/s/prices',
        },
        {
          uri: '/audiovideo_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'avt',
          dataSource: '/v1/values/audio-video/types',
        },
      ],
    },
    '11_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/audiovideo_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'avt',
          dataSource: '/v1/values/audio-video/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/audiovideo_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'avt',
          dataSource: '/v1/values/audio-video/types',
        },
      ],
    },
    '11_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/11/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/11/advertiser_types',
        },
      ],
    },
    '40_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/40/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/40/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/40/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/40/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/40/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/40/adtypes/s/prices',
        },
      ],
    },
    '40_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '40_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/40/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/40/advertiser_types',
        },
      ],
    },
    '12_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/12/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/12/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/12/adtypes/s/prices',
          },
        },
        {
          uri: '/phone_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'pht',
          dataSource: '/v1/values/phones/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/12/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/12/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/12/adtypes/s/prices',
        },
        {
          uri: '/phone_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'pht',
          dataSource: '/v1/values/phones/types',
        },
      ],
    },
    '12_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/phone_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'pht',
          dataSource: '/v1/values/phones/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/phone_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'pht',
          dataSource: '/v1/values/phones/types',
        },
      ],
    },
    '12_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/12/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/12/advertiser_types',
        },
      ],
    },
    '13_s': {
      filters: [
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/13/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/13/adtypes/s/prices',
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/13/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/13/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/13/adtypes/s/prices',
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/13/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '13_g': {
      filters: [],
      sparseFilters: [],
    },
    '13_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/13/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/13/advertiser_types',
        },
      ],
    },
    '14_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/14/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/14/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/14/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/14/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/14/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/14/adtypes/s/prices',
        },
      ],
    },
    '14_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '14_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/14/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/14/advertiser_types',
        },
      ],
    },
    '37_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/37/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/37/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/37/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/37/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/37/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/37/adtypes/s/prices',
        },
      ],
    },
    '37_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '37_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/37/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/37/advertiser_types',
        },
      ],
    },
    '15_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/15/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/15/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/15/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/15/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/15/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/15/adtypes/s/prices',
        },
      ],
    },
    '15_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '15_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/15/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/15/advertiser_types',
        },
      ],
    },
    '16_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/16/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'articolo",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/16/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/16/adtypes/s/prices',
          },
        },
        {
          uri: '',
          label: '',
          type: 'linked',
          filters: [
            {
              uri: '/fashion/clothing_type',
              label: 'Tipologia',
              type: 'list',
              queryString: 'clt',
              dataSource: '/v1/values/fashion/types',
            },
            {
              uri: '/fashion/brand',
              label: 'Marca',
              type: 'list',
              queryString: 'fbrnd',
              dataSource:
                '/v1/values/fashion/types/{/fashion/clothing_type}/brands',
              multiSelection: true,
            },
            {
              uri: '/fashion/size',
              label: 'Taglia',
              type: 'list',
              queryString: 'fsz',
              dataSource:
                '/v1/values/fashion/types/{/fashion/clothing_type}/sizes',
              multiSelection: true,
            },
          ],
        },
        {
          uri: '/clothing_gender',
          label: 'Genere',
          type: 'list',
          queryString: 'clg',
          dataSource: '/v1/values/clothings/genders',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/16/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'articolo",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/16/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/16/adtypes/s/prices',
        },
        {
          uri: '/fashion/clothing_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'clt',
          dataSource: '/v1/values/fashion/types',
        },
        {
          uri: '/fashion/brand',
          label: 'Marca',
          type: 'list',
          queryString: 'fbrnd',
          dataSource:
            '/v1/values/fashion/types/{/fashion/clothing_type}/brands',
          multiSelection: true,
        },
        {
          uri: '/fashion/size',
          label: 'Taglia',
          type: 'list',
          queryString: 'fsz',
          dataSource: '/v1/values/fashion/types/{/fashion/clothing_type}/sizes',
          multiSelection: true,
        },
        {
          uri: '/clothing_gender',
          label: 'Genere',
          type: 'list',
          queryString: 'clg',
          dataSource: '/v1/values/clothings/genders',
        },
      ],
    },
    '16_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'articolo",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '',
          label: '',
          type: 'linked',
          filters: [
            {
              uri: '/fashion/clothing_type',
              label: 'Tipologia',
              type: 'list',
              queryString: 'clt',
              dataSource: '/v1/values/fashion/types',
            },
            {
              uri: '/fashion/brand',
              label: 'Marca',
              type: 'list',
              queryString: 'fbrnd',
              dataSource:
                '/v1/values/fashion/types/{/fashion/clothing_type}/brands',
              multiSelection: true,
            },
            {
              uri: '/fashion/size',
              label: 'Taglia',
              type: 'list',
              queryString: 'fsz',
              dataSource:
                '/v1/values/fashion/types/{/fashion/clothing_type}/sizes',
              multiSelection: true,
            },
          ],
        },
        {
          uri: '/clothing_gender',
          label: 'Genere',
          type: 'list',
          queryString: 'clg',
          dataSource: '/v1/values/clothings/genders',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'articolo",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/fashion/clothing_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'clt',
          dataSource: '/v1/values/fashion/types',
        },
        {
          uri: '/fashion/brand',
          label: 'Marca',
          type: 'list',
          queryString: 'fbrnd',
          dataSource:
            '/v1/values/fashion/types/{/fashion/clothing_type}/brands',
          multiSelection: true,
        },
        {
          uri: '/fashion/size',
          label: 'Taglia',
          type: 'list',
          queryString: 'fsz',
          dataSource: '/v1/values/fashion/types/{/fashion/clothing_type}/sizes',
          multiSelection: true,
        },
        {
          uri: '/clothing_gender',
          label: 'Genere',
          type: 'list',
          queryString: 'clg',
          dataSource: '/v1/values/clothings/genders',
        },
      ],
    },
    '16_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/16/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/16/advertiser_types',
        },
      ],
    },
    '17_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/17/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/17/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/17/adtypes/s/prices',
          },
        },
        {
          uri: '/children_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'cht',
          dataSource: '/v1/values/children/types',
        },
        {
          uri: '/children_age',
          label: 'Fascia di età',
          type: 'list',
          queryString: 'cha',
          dataSource: '/v1/values/children/ages',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/17/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/17/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/17/adtypes/s/prices',
        },
        {
          uri: '/children_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'cht',
          dataSource: '/v1/values/children/types',
        },
        {
          uri: '/children_age',
          label: 'Fascia di età',
          type: 'list',
          queryString: 'cha',
          dataSource: '/v1/values/children/ages',
        },
      ],
    },
    '17_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/children_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'cht',
          dataSource: '/v1/values/children/types',
        },
        {
          uri: '/children_age',
          label: 'Fascia di età',
          type: 'list',
          queryString: 'cha',
          dataSource: '/v1/values/children/ages',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/children_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'cht',
          dataSource: '/v1/values/children/types',
        },
        {
          uri: '/children_age',
          label: 'Fascia di età',
          type: 'list',
          queryString: 'cha',
          dataSource: '/v1/values/children/ages',
        },
      ],
    },
    '17_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/17/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/17/advertiser_types',
        },
      ],
    },
    '18_s': {
      filters: [
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/18/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/18/adtypes/s/prices',
          },
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/18/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/18/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/18/adtypes/s/prices',
        },
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/18/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '18_g': {
      filters: [],
      sparseFilters: [],
    },
    '18_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/18/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/18/advertiser_types',
        },
      ],
    },
    '23_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/23/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/23/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/23/adtypes/s/prices',
          },
        },
        {
          uri: '/animal_type',
          label: 'Specie',
          type: 'list',
          queryString: 'at',
          dataSource: '/v1/values/animals/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/23/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/23/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/23/adtypes/s/prices',
        },
        {
          uri: '/animal_type',
          label: 'Specie',
          type: 'list',
          queryString: 'at',
          dataSource: '/v1/values/animals/types',
        },
      ],
    },
    '23_g': {
      filters: [
        {
          uri: '/animal_type',
          label: 'Specie',
          type: 'list',
          queryString: 'at',
          dataSource: '/v1/values/animals/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/animal_type',
          label: 'Specie',
          type: 'list',
          queryString: 'at',
          dataSource: '/v1/values/animals/types',
        },
      ],
    },
    '23_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/23/advertiser_types',
        },
        {
          uri: '/animal_type',
          label: 'Specie',
          type: 'list',
          queryString: 'at',
          dataSource: '/v1/values/animals/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/23/advertiser_types',
        },
        {
          uri: '/animal_type',
          label: 'Specie',
          type: 'list',
          queryString: 'at',
          dataSource: '/v1/values/animals/types',
        },
      ],
    },
    '100_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/100/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/100/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/100/adtypes/s/prices',
          },
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/100/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/100/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/100/adtypes/s/prices',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '100_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '19_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/19/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/19/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/19/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/19/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/19/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/19/adtypes/s/prices',
        },
      ],
    },
    '19_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '19_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/19/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/19/advertiser_types',
        },
      ],
    },
    '38_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/38/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/38/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/38/adtypes/s/prices',
          },
        },
        {
          uri: '/book_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bot',
          dataSource: '/v1/values/books/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/38/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/38/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/38/adtypes/s/prices',
        },
        {
          uri: '/book_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bot',
          dataSource: '/v1/values/books/types',
        },
      ],
    },
    '38_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/book_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bot',
          dataSource: '/v1/values/books/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/book_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bot',
          dataSource: '/v1/values/books/types',
        },
      ],
    },
    '38_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/38/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/38/advertiser_types',
        },
      ],
    },
    '39_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/39/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/39/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/39/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/39/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/39/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/39/adtypes/s/prices',
        },
      ],
    },
    '39_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
      ],
    },
    '39_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/39/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/39/advertiser_types',
        },
      ],
    },
    '20_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/20/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/20/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/20/adtypes/s/prices',
          },
        },
        {
          uri: '/sport_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'st',
          dataSource: '/v1/values/sports/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/20/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/20/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/20/adtypes/s/prices',
        },
        {
          uri: '/sport_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'st',
          dataSource: '/v1/values/sports/types',
        },
      ],
    },
    '20_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/sport_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'st',
          dataSource: '/v1/values/sports/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/sport_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'st',
          dataSource: '/v1/values/sports/types',
        },
      ],
    },
    '20_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/20/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/20/advertiser_types',
        },
      ],
    },
    '41_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/41/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/41/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/41/adtypes/s/prices',
          },
        },
        {
          uri: '/bicycle_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bkt',
          dataSource: '/v1/values/bicycles/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/41/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/41/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/41/adtypes/s/prices',
        },
        {
          uri: '/bicycle_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bkt',
          dataSource: '/v1/values/bicycles/types',
        },
      ],
    },
    '41_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/bicycle_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bkt',
          dataSource: '/v1/values/bicycles/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/bicycle_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'bkt',
          dataSource: '/v1/values/bicycles/types',
        },
      ],
    },
    '41_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/41/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/41/advertiser_types',
        },
      ],
    },
    '21_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/21/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/21/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/21/adtypes/s/prices',
          },
        },
        {
          uri: '/hobby_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ht',
          dataSource: '/v1/values/collectibles/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/21/advertiser_types',
        },
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/21/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/21/adtypes/s/prices',
        },
        {
          uri: '/hobby_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ht',
          dataSource: '/v1/values/collectibles/types',
        },
      ],
    },
    '21_g': {
      filters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/hobby_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ht',
          dataSource: '/v1/values/collectibles/types',
        },
      ],
      sparseFilters: [
        {
          uri: '/item_condition',
          label: "Condizione dell'oggetto",
          type: 'list',
          queryString: 'ic',
          dataSource: '/v1/values/item_condition/types',
          multiSelection: true,
          metadata: {
            is_new: false,
          },
        },
        {
          uri: '/hobby_type',
          label: 'Tipologia',
          type: 'list',
          queryString: 'ht',
          dataSource: '/v1/values/collectibles/types',
        },
      ],
    },
    '21_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/21/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/21/advertiser_types',
        },
      ],
    },
    '27_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/28/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/28/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/28/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/28/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/28/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/28/adtypes/s/prices',
        },
      ],
    },
    '27_g': {
      filters: [],
      sparseFilters: [],
    },
    '27_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/28/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/28/advertiser_types',
        },
      ],
    },
    '28_s': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/28/advertiser_types',
        },
        {
          uri: '/price',
          label: 'Prezzo (€)',
          type: 'range',
          min: {
            uri: '/price/min',
            label: 'da',
            type: 'list',
            queryString: 'ps',
            dataSource: '/v1/values/categories/28/adtypes/s/prices',
          },
          max: {
            uri: '/price/max',
            label: 'a',
            type: 'list',
            queryString: 'pe',
            dataSource: '/v1/values/categories/28/adtypes/s/prices',
          },
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/28/advertiser_types',
        },
        {
          uri: '/price/min',
          label: 'da',
          type: 'list',
          queryString: 'ps',
          dataSource: '/v1/values/categories/28/adtypes/s/prices',
        },
        {
          uri: '/price/max',
          label: 'a',
          type: 'list',
          queryString: 'pe',
          dataSource: '/v1/values/categories/28/adtypes/s/prices',
        },
      ],
    },
    '28_g': {
      filters: [],
      sparseFilters: [],
    },
    '28_k': {
      filters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/28/advertiser_types',
        },
      ],
      sparseFilters: [
        {
          uri: '/advertiser_type',
          label: 'Inserzionista',
          type: 'list',
          queryString: 'advt',
          dataSource: '/v1/values/categories/28/advertiser_types',
        },
      ],
    },
  },
};

export default store;
