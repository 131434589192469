import {
  ADSENSE_STYLES,
  AdSenseVariations,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'msite-middle-lp-tutti',
  Jobs = 'msite-middle-lp-jobs',
  Motor = 'msite-middle-lp-motor',
  Market = 'msite-middle-lp-market',
  RealEstate = 'msite-middle-lp-realestate',
}

const msiteMiddleDefault: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_TUTTI, //'6030888272',
    channel: 'MSITE-MIDDLE-LP-TUTTI-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddleJob: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: ADSENSE_STYLES.NO_SHOPPING_MOBILE_SEARCH_LIGHT_JOBS, //'8434780006',
    channel: 'MSITE-MIDDLE-LP-JOBS-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddleMotor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_MOTORS, //'6162928547',
    channel: 'MSITE-MIDDLE-LP-MOTORS-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddleMarket: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_MARKET, //'9747862309',
    channel: 'MSITE-MIDDLE-LP-MARKET-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddleRealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_REALESTATE, //'1186692828',
    channel: 'MSITE-MIDDLE-LP-REALESTATE-0-LIGHT-NO',
  },
  adBlockOptions: { number: 1 },
};

const msiteMiddle0: Array<ShortConfiguration> = [
  msiteMiddleDefault,
  msiteMiddleJob,
  msiteMiddleMotor,
  msiteMiddleMarket,
  msiteMiddleRealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { msiteMiddle0, variations as MsiteMiddle0 };
