import type { SubitoAction } from '@reducers/common';
import { GeoEntry } from '@sbt-web/network/types';
import {
  AdType,
  BaseCategory,
  CategoryId,
  CategoryStore,
  HereAddress,
  isCategory,
  KeyValuePair,
  Orders,
  TuttaItalia,
} from '@sbt-web/networking';
import { dispatchAsyncEvent } from '@tools/eventHelpers';
import { getFiltersConfigStore } from '@tools/search';
import { FilterURI } from '@tools/search/values';
import { Reducer } from 'redux';

const TutteLeCategorie = CategoryStore.getCategoryById(CategoryId.Tutte);

const uuid = (): string | null => {
  return __SERVER__ ? null : crypto.randomUUID();
};

const serversideSearchOrigin = 'ServersideSearch';

export const defaultState: SearchState = {
  id: null,
  query: '',
  category: TutteLeCategorie,
  adType: TutteLeCategorie.defaultAdType,
  geo: {
    region: TuttaItalia,
  },
  qso: false,
  includeShippableOnly: false,
  includeUrgent: false,
  filters: {},
  filtersLabels: {},
  page: 1,
  orderIndex: 0,
  isUpdating: false,
  filtersDialogOpen: false,
  radiusDialogOpen: false,
  filterOrigin: serversideSearchOrigin,
};

function getFilterUri(
  filterKey: string,
  search: SearchState
): string | undefined {
  const currentConfig = getFiltersConfigStore(
    search.category.id,
    search.adType
  );
  const filter = currentConfig?.filterForShortName(filterKey);

  if (filter) {
    return filter.uri;
  }
}

function dispatchFilterChangedEvent(uri: string): void {
  dispatchAsyncEvent(`subito:filterChanged:${uri}`);
}

const updateFilterOriginOnClient = (origin?: string): string | undefined => {
  if (__SERVER__) {
    return serversideSearchOrigin;
  } else {
    return origin;
  }
};

const immobiliCategoryIds = [
  CategoryId.Immobili,
  CategoryId.Appartamenti,
  CategoryId.CamerePostiLetto,
  CategoryId.Ville,
  CategoryId.TerreniRustici,
  CategoryId.GarageBox,
  CategoryId.LoftMansarde,
  CategoryId.CasaPersona,
  CategoryId.UfficiLocali,
];

export const search: Reducer<SearchState, SearchActions> = (
  state = defaultState,
  action
): SearchState => {
  switch (action.type) {
    case Types.UPDATE_SEARCH_ID: {
      return { ...state, id: action.payload };
    }
    case Types.UPDATE_CATEGORY: {
      if (state.category.id === action.payload.id) {
        return state;
      }

      dispatchFilterChangedEvent(FilterURI.Category);

      // Specifically reset the location when leaving immobili
      // if there is an active radius search.
      if (
        !immobiliCategoryIds.includes(action.payload.id) &&
        state.radiusSearch != null
      ) {
        return {
          ...state,
          id: uuid(),
          page: 1,
          category: action.payload,
          adType: action.payload.defaultAdType,
          geo: {
            region: TuttaItalia,
          },
          orderIndex: 0,
          filters: {},
          filtersLabels: {},
          includeShippableOnly: false,
          includeUrgent: false,
          radiusSearch: null,
          filterOrigin: updateFilterOriginOnClient('CategoryComponent'),
        };
      }

      return {
        ...state,
        id: uuid(),
        page: 1,
        category: action.payload,
        adType: action.payload.defaultAdType,
        geo: {
          ...state.geo,
          zone:
            isCategory(action.payload) && action.payload.zoneSupport
              ? state.geo.zone
              : undefined,
        },
        orderIndex: 0,
        filters: {},
        filtersLabels: {},
        includeShippableOnly: false,
        includeUrgent: false,
        filterOrigin: updateFilterOriginOnClient('CategoryComponent'),
      };
    }
    case Types.UPDATE_GEO: {
      if (state.geo === action.payload.geo || action.payload.geo == null) {
        return state;
      }
      dispatchFilterChangedEvent(FilterURI.Geo);
      return {
        ...state,
        id: uuid(),
        page: 1,
        geo: action.payload.geo,
        searchNearRegions: action.payload.searchNearRegions,
        radiusSearch: null,
        filterOrigin: updateFilterOriginOnClient('GeoSearch'),
      };
    }
    case Types.UPDATE_RADIUS_SEARCH: {
      dispatchFilterChangedEvent(FilterURI.Geo);
      // The other filters are missing from the URI list
      return {
        ...state,
        id: uuid(),
        page: 1,
        radiusSearch: action.payload.radiusSearch,
        geo: { region: TuttaItalia },
        searchNearRegions: false,
        filterOrigin: updateFilterOriginOnClient(
          action.payload.isFinalSearch ? 'RadiusDialog' : undefined
        ),
      };
    }
    case Types.UPDATE_QUERY:
      if (state.query === action.payload) {
        return state;
      }
      dispatchFilterChangedEvent('/query'); // This is kept as a string because the real URI is different
      return {
        ...state,
        id: uuid(),
        page: 1,
        query: action.payload,
        filterOrigin: updateFilterOriginOnClient('MainSearch'),
      };
    case Types.UPDATE_ADTYPE:
      if (state.adType === action.payload) {
        return state;
      }
      dispatchFilterChangedEvent(FilterURI.Type);
      return {
        ...state,
        id: uuid(),
        adType: action.payload,
        page: 1,
        filters: {},
        filtersLabels: {},
        includeShippableOnly: false,
        includeUrgent: false,
        filterOrigin: updateFilterOriginOnClient(
          state.filtersDialogOpen ? undefined : 'FilterUI'
        ),
      };
    case Types.UPDATE_QSO:
      dispatchFilterChangedEvent(FilterURI.QSO);
      return {
        ...state,
        id: uuid(),
        page: 1,
        qso: action.payload,
        filterOrigin: updateFilterOriginOnClient(
          state.filtersDialogOpen ? undefined : 'FilterUI'
        ),
      };
    case Types.UPDATE_SHIPPABLE_ONLY:
      dispatchFilterChangedEvent(FilterURI.ItemShippable);
      return {
        ...state,
        id: uuid(),
        page: 1,
        includeShippableOnly: action.payload,
        filterOrigin: updateFilterOriginOnClient(
          state.filtersDialogOpen ? undefined : 'FilterUI'
        ),
      };
    case Types.UPDATE_URGENT:
      dispatchFilterChangedEvent(FilterURI.ItemUrgent);
      return {
        ...state,
        id: uuid(),
        page: 1,
        includeUrgent: action.payload,
        filterOrigin: updateFilterOriginOnClient(
          state.filtersDialogOpen ? undefined : 'FilterUI'
        ),
      };
    case Types.UPDATE_FILTER: {
      let newState: SearchState;

      if (action.payload.value === state.filters[action.payload.key]) {
        return state;
      }

      if (action.payload.value) {
        newState = {
          ...state,
          id: uuid(),
          page: 1,
          filters: {
            ...state.filters,
            [action.payload.key]: action.payload.value,
          },
          filterOrigin: updateFilterOriginOnClient(
            state.filtersDialogOpen ? undefined : 'FilterUI'
          ),
        };
        delete newState.filtersLabels[action.payload.key];
      } else {
        const newFilters = Object.assign({}, state.filters);
        delete newFilters[action.payload.key];
        newState = {
          ...state,
          id: uuid(),
          page: 1,
          filters: newFilters,
          filterOrigin: updateFilterOriginOnClient(
            state.filtersDialogOpen ? undefined : 'FilterUI'
          ),
        };
      }
      const uri = getFilterUri(action.payload.key, state);
      if (uri) {
        dispatchFilterChangedEvent(uri);
      }
      return newState;
    }
    case Types.DECLARE_FINAL_SEARCH: {
      return {
        ...state,
        id: uuid(),
        filterOrigin: updateFilterOriginOnClient('FilterUI'),
      };
    }
    case Types.UPDATE_FILTER_LABEL: {
      if (action.payload.value) {
        return {
          ...state,
          id: uuid(),
          page: 1,
          filtersLabels: {
            ...state.filtersLabels,
            [action.payload.key]: action.payload.value,
          },
        };
      } else {
        const newFilters = Object.assign({}, state.filtersLabels);
        delete newFilters[action.payload.key];
        return {
          ...state,
          id: uuid(),
          page: 1,
          filtersLabels: newFilters,
        };
      }
    }
    case Types.UPDATE_FILTER_LABELS_BULK: {
      return {
        ...state,
        id: uuid(),
        filtersLabels: action.payload,
      };
    }
    case Types.RESET_FILTERS: {
      dispatchFilterChangedEvent('reset');
      return {
        ...state,
        id: uuid(),
        qso: false,
        adType: state.category.defaultAdType,
        page: 1,
        filters: {},
        filtersLabels: {},
        filterOrigin: updateFilterOriginOnClient('FilterReset'),
      };
    }
    case Types.SET_FILTERS_DIALOG_STATUS:
      dispatchAsyncEvent(`subito:listingCovered:${action.payload}`);
      return {
        ...state,
        filtersDialogOpen: action.payload,
      };
    case Types.SET_RADIUS_DIALOG_STATUS:
      dispatchAsyncEvent(`subito:listingCovered:${action.payload}`);
      return {
        ...state,
        radiusDialogOpen: action.payload,
      };
    case Types.START_UPDATING:
      return {
        ...state,
        isUpdating: true,
      };
    case Types.STOP_UPDATING:
      return {
        ...state,
        isUpdating: false,
      };
    case Types.UPDATE_ORDER: {
      const index = state.category.orders.findIndex(
        (o) => o === action.payload
      );

      if (index === state.orderIndex) {
        return state;
      }

      dispatchFilterChangedEvent(FilterURI.Order);
      return {
        ...state,
        id: uuid(),
        orderIndex: index >= 0 ? index : 0,
        page: 1,
        filterOrigin: updateFilterOriginOnClient('OrderComponent'),
      };
    }
    case Types.UPDATE_PAGE:
      dispatchFilterChangedEvent(FilterURI.Page);
      return {
        ...state,
        page: action.payload,
        filterOrigin: updateFilterOriginOnClient('PaginationComponent'),
      };
    case Types.RECENT_SEARCH: {
      const {
        qso,
        shp: includeShippableOnly,
        urg: includeUrgent,
        filters,
      } = action.payload?.allFilters ?? {
        qso: false,
        shp: false,
        urg: false,
        filters: undefined,
      };

      return {
        ...state,
        id: uuid(),
        adType: action.payload.adType,
        query: action.payload.query,
        category: action.payload.category,
        geo: action.payload.geo.geoValues,
        radiusSearch: action.payload.geo.radiusValues,
        qso,
        includeShippableOnly,
        includeUrgent,
        filters: filters ?? {},
        searchNearRegions: action.payload.searchNearRegions,
      };
    }
    case Types.UPDATE_SEARCH: {
      return {
        ...state,
        ...action.payload,
        id: uuid(),
      };
    }
    default:
      return state;
  }
};

enum Types {
  UPDATE_SEARCH_ID = 'update search id',
  UPDATE_QUERY = 'update query',
  UPDATE_CATEGORY = 'update category',
  UPDATE_GEO = 'update geo',
  UPDATE_RADIUS_SEARCH = 'update radius search',
  UPDATE_ADTYPE = 'update adtype',
  UPDATE_QSO = 'update query search only',
  UPDATE_SHIPPABLE_ONLY = 'update shippable only',
  UPDATE_URGENT = 'update urgent',
  UPDATE_FILTER = 'update dynamic filter',
  DECLARE_FINAL_SEARCH = 'update finality of search',
  UPDATE_FILTER_LABEL = 'update dynamic filter label',
  UPDATE_FILTER_LABELS_BULK = 'update dynamic filter labels (bulk)',
  RESET_FILTERS = 'reset filters',
  SET_FILTERS_DIALOG_STATUS = 'set filters dialog status',
  SET_RADIUS_DIALOG_STATUS = 'set radius dialog status',
  START_UPDATING = 'start updating search',
  STOP_UPDATING = 'stop updating search',
  UPDATE_ORDER = 'update order',
  UPDATE_PAGE = 'update page',
  RECENT_SEARCH = 'recent search',
  UPDATE_SEARCH = 'update search',
}

type SearchActions =
  | ReturnType<typeof updateSearchId>
  | ReturnType<typeof updateQuery>
  | ReturnType<typeof updateCategory>
  | ReturnType<typeof updateGeo>
  | ReturnType<typeof updateRadiusSearch>
  | ReturnType<typeof updateAdType>
  | ReturnType<typeof updateQSO>
  | ReturnType<typeof updateShippableOnly>
  | ReturnType<typeof updateUrgent>
  | ReturnType<typeof updateFilter>
  | ReturnType<typeof declareFinalSearch>
  | ReturnType<typeof updateFilterLabel>
  | ReturnType<typeof updateFiltersLabelsBulk>
  | ReturnType<typeof resetFilters>
  | ReturnType<typeof setFiltersDialogStatus>
  | ReturnType<typeof setRadiusDialogStatus>
  | ReturnType<typeof startUpdating>
  | ReturnType<typeof stopUpdating>
  | ReturnType<typeof updateOrder>
  | ReturnType<typeof updatePage>
  | ReturnType<typeof updateRecentSearch>
  | ReturnType<typeof updateSearch>;

export function updateSearchId(
  id: string
): SubitoAction<Types.UPDATE_SEARCH_ID, string> {
  return { type: Types.UPDATE_SEARCH_ID, payload: id };
}

export function updateCategory(
  category: BaseCategory
): SubitoAction<Types.UPDATE_CATEGORY, BaseCategory> {
  return { type: Types.UPDATE_CATEGORY, payload: category };
}

export function updateGeo(
  geo: GeoEntry,
  searchNearRegions = false
): SubitoAction<
  Types.UPDATE_GEO,
  { geo: GeoEntry; searchNearRegions: boolean }
> {
  return { type: Types.UPDATE_GEO, payload: { geo, searchNearRegions } };
}

export function updateRadiusSearch({
  radiusSearch,
  isFinalSearch,
}: {
  radiusSearch: RadiusSearch;
  isFinalSearch: boolean;
}): SubitoAction<
  Types.UPDATE_RADIUS_SEARCH,
  { radiusSearch: RadiusSearch; isFinalSearch: boolean }
> {
  return {
    type: Types.UPDATE_RADIUS_SEARCH,
    payload: {
      radiusSearch,
      isFinalSearch,
    },
  };
}

export function updateQuery(
  query: string | undefined
): SubitoAction<Types.UPDATE_QUERY, string | undefined> {
  return {
    type: Types.UPDATE_QUERY,
    payload: query,
  };
}

export function updateAdType(
  adType: AdType
): SubitoAction<Types.UPDATE_ADTYPE, AdType> {
  return {
    type: Types.UPDATE_ADTYPE,
    payload: adType,
  };
}

export function updateQSO(
  qso: boolean
): SubitoAction<Types.UPDATE_QSO, boolean> {
  return {
    type: Types.UPDATE_QSO,
    payload: qso,
  };
}

export function updateShippableOnly(
  isShippableOnly: boolean
): SubitoAction<Types.UPDATE_SHIPPABLE_ONLY, boolean> {
  return {
    type: Types.UPDATE_SHIPPABLE_ONLY,
    payload: isShippableOnly,
  };
}

export function updateUrgent(
  isUrgent: boolean
): SubitoAction<Types.UPDATE_URGENT, boolean> {
  return {
    type: Types.UPDATE_URGENT,
    payload: isUrgent,
  };
}

export function updateFilter(
  filter: KeyValuePair
): SubitoAction<Types.UPDATE_FILTER, KeyValuePair> {
  return {
    type: Types.UPDATE_FILTER,
    payload: filter,
  };
}

export function declareFinalSearch(): SubitoAction<
  Types.DECLARE_FINAL_SEARCH,
  null
> {
  return {
    type: Types.DECLARE_FINAL_SEARCH,
    payload: null,
  };
}

export function updateFilterLabel(
  filterLabel: KeyValuePair
): SubitoAction<Types.UPDATE_FILTER_LABEL, KeyValuePair> {
  return {
    type: Types.UPDATE_FILTER_LABEL,
    payload: filterLabel,
  };
}

export function updateFiltersLabelsBulk(
  filtersLabels: Record<string, string>
): SubitoAction<Types.UPDATE_FILTER_LABELS_BULK, Record<string, string>> {
  return {
    type: Types.UPDATE_FILTER_LABELS_BULK,
    payload: filtersLabels,
  };
}

export function resetFilters(): SubitoAction<Types.RESET_FILTERS> {
  return {
    type: Types.RESET_FILTERS,
    payload: undefined,
  };
}

export function setFiltersDialogStatus(
  opened: boolean
): SubitoAction<Types.SET_FILTERS_DIALOG_STATUS, boolean> {
  return {
    type: Types.SET_FILTERS_DIALOG_STATUS,
    payload: opened,
  };
}

export function setRadiusDialogStatus(
  opened: boolean
): SubitoAction<Types.SET_RADIUS_DIALOG_STATUS, boolean> {
  return {
    type: Types.SET_RADIUS_DIALOG_STATUS,
    payload: opened,
  };
}

export function startUpdating(): SubitoAction<Types.START_UPDATING> {
  return {
    type: Types.START_UPDATING,
    payload: undefined,
  };
}

export function stopUpdating(): SubitoAction<Types.STOP_UPDATING> {
  return {
    type: Types.STOP_UPDATING,
    payload: undefined,
  };
}

export function updateOrder(
  order: Orders
): SubitoAction<Types.UPDATE_ORDER, Orders> {
  return {
    type: Types.UPDATE_ORDER,
    payload: order,
  };
}

export function updatePage(
  page: number
): SubitoAction<Types.UPDATE_PAGE, number> {
  return {
    type: Types.UPDATE_PAGE,
    payload: page,
  };
}
export function updateRecentSearch(
  recentSearch: RecentSearchOption
): SubitoAction<Types.RECENT_SEARCH, RecentSearchOption> {
  return {
    type: Types.RECENT_SEARCH,
    payload: recentSearch,
  };
}

export function updateSearch(
  search: SearchState
): SubitoAction<Types.UPDATE_SEARCH, SearchState> {
  return {
    type: Types.UPDATE_SEARCH,
    payload: search,
  };
}

interface RecentSearchOption {
  query?: string;
  category: BaseCategory;
  searchNearRegions: boolean;
  adType: AdType;
  geo: {
    geoValues: GeoEntry;
    radiusValues: RadiusSearch | null;
  };
  allFilters: {
    qso: boolean;
    shp: boolean;
    urg: boolean;
    filters?: Record<string, string>;
  };
}

export interface RadiusSearch {
  center: { lat: number; lng: number };
  radiusMeters: number;
  friendlyName: string;
  addressToTrack?: HereAddress;
}

/**
 * Any change to the search state on the listing will trigger a reload of the
 * ads on the page.
 */
export interface SearchState {
  id?: string | null;
  query?: string;
  category: BaseCategory;
  geo: GeoEntry;
  searchNearRegions?: boolean;
  radiusSearch?: RadiusSearch | null;
  qso: boolean;
  includeShippableOnly: boolean;
  includeUrgent: boolean;
  adType: AdType;
  filters: Record<string, string>;
  filtersLabels: Record<string, string>;
  filtersDialogOpen: boolean;
  radiusDialogOpen: boolean;
  isUpdating: boolean;
  page: number;
  orderIndex: number;
  filterOrigin?: string;
}
